<template>
  <div class="md-topic-detail-pages-component">
    <div class="title">
      {{ topicInfo.title }}
    </div>
    <div class="content">
      <div class="content-left">
        <div class="content-header">
          <div>
            <div style="font-weight: bold; font-size: 13px;">第{{ toChineseNumber(topicIndex + 1) }}部分：{{
              topics[topicIndex].title }}</div>
            <el-tag type="danger">{{ renderTitle() }}</el-tag>
            <div> <el-button size="small" @click="goUp" :disabled="topicIndex === 0">上一题</el-button> <el-button
                size="small" @click="goNext" :disabled="topicIndex === topics.length - 1">下一题</el-button> </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- <p style="padding: 10px 20px 0px 20px;" v-if="topics[topicIndex].title !== '阅读理解'"><el-tag>{{
          topics[topicIndex].title }}</el-tag></p> -->
        <template>
          <div class="topic-item" :id="`scrollTip${key}`" v-for="(item, key) in topics[topicIndex].topic" :key="key">
            <MDTopicItemClass v-model="item.answer" :topicItemInfo="item" @clearNoAnswer="clearNoAnswer(key)" />
          </div>
        </template>
      </div>
      <div class="content-right">
        <div class="font-regular">
          <div style="margin: 0 auto;"> 您的分数为：{{ topicInfo.grade == 'NaN' ? 0 : topicInfo.grade }}</div>

        </div>
        <el-divider class="margin-driver"></el-divider>
        <!-- <el-button class="submit-btn" @click="onSubmit" borderColor="#fe6b03">交卷</el-button> -->

        <!-- //总分 -->
        <div class="font-assistant">
          <div> <span> 总分：</span> <span style="color: red;"> {{ topicInfo.total || 710 }}</span> </div>
          <div> <span> 题数：</span> <span style="color: red;"> {{ alltotal }}</span></div>
          <div><span> 难度系数</span> <span style="color: red;"> 0.8</span> </div>
        </div>
        <el-divider border-style="dashed"></el-divider>
        <!-- 答题卡
           -->

        <div>
          <v-scroll>
            <div v-for="(list, key) in topics" :key="key" style="margin-top: 20px;">
              <div class="font-regular">{{ toChineseNumber(key + 1) }}、{{ list.title }}</div>
              <div class="font-content">
                <div v-for="(item, index) in list.topic" :key="index" style="display: flex;flex-wrap: wrap;">
                  <div :class="`${item.answer !== null ? 'green' : 'question-tag'}`" v-if="item.num"
                    @click="gotopic(key, item.num)">{{ item.num }}</div>
                  <div :class="`${question.answer !== null ? 'green' : 'question-tag'}`" v-else
                    @click="gotopic(key, question.num)" v-for="(question, key1) in item.questionList" :key="key1">{{
                      question.num
                    }}
                  </div>
                </div>
              </div>
            </div>
          </v-scroll>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectAnswerRecordInfo, selectAnswerRecordInfocontent } from '@/axios/topics'
import MDTopicItemClass from '../components/class/mdTopicItemCheck/mdTopicltems.vue'
export default {
  components: {
    MDTopicItemClass
  },
  data() {
    return {
      topicId: '',
      topicInfo: {
        title: '',
        grade: 0,
        content: []
      },
      noAnswerIndexAry: [],
      noAnswerIndex: null,
      alltotal: null,//这个试卷题的总数
      topics: [],//题的数组
      topicIndex: 0, //题的坐标,控制是什么大题，
      ComprehensionIndex: 0,//阅读理解的坐标，显示是第几个题
    }
  },
  created() {
    const topicId = this.$route.query.rid
    this.selectAnswerRecordInfo(topicId)
  },
  methods: {
    async selectAnswerRecordInfo(id) {
      const res = await selectAnswerRecordInfo(id)
      const res1 = await selectAnswerRecordInfocontent(id)
      if (res.code === 200) {
        console.log(res)
        this.topicInfo = res.data

      }

      if (res1.code == 200) {
        console.log(res1.data[0].content,'--------------------------------top');
        
        this.topicInfo.content = JSON.parse(res1.data[0].content).map(item => {
          return {
            ...item,
            rightAnswerLabel: item.rightAnswerLabel.join(''),
            questionList: item.questionList.map(q => {
              return {
                ...q,
                rightAnswerLabel: q.rightAnswerLabel.join('')
              }
            })
          }
        })

        
      }

     
    },
    // 将题分为  阅读理解、完型填空
    //处理题
    changeInfo() {
      this.topics = []
      //完形填空
      let ReadingList = this.topicInfo.content.filter(item => item.questionType == 4)
      // 阅读理解
      let fillblanksList = this.topicInfo.content.filter(item => item.questionType == 3)
      //单选题
      let radioList = this.topicInfo.content.filter(item => item.questionType == 1)
      let hearing = this.topicInfo.content.filter(item => item.questionType == 2)
      //作文
      let composition = this.topicInfo.content.filter(item => item.questionType == 5);


      //听力
      if (ReadingList.length > 0) {
        let title = ReadingList.reduce((prev, cur) => {
          return prev + cur.questionList.length;
        }, 0) //注意这里设置了初始值
        this.topics.push({ title: '听力', topic: ReadingList, total: title });

      }

      // 听力
      if (hearing.length > 0) {
        let hearingtitle = hearing.reduce((prev, cur) => {
          return prev + cur.questionList.length;
        }, 0) //注意这里设置了初始值
        this.topics.push({ title: '听力', topic: hearing, total: hearingtitle })
      }
      // 阅读理解
      if (fillblanksList.length > 0) {
        //需要替换的字符
        const parameter1 = 'video-api'
        //需要替换成的字符
        const parameter2 = 'video-dev'
        let name = []
        //替换字符
        let lastfillblanksList = fillblanksList.map(item => {
          return { ...item, articleCon: item.articleCon ? item.articleCon.replace(parameter1, parameter2) : '' }
        })

        lastfillblanksList.forEach(item => {
          if (!name.includes(item.articleName)) {
            name.push(item.articleName)
          }
        });
        name.forEach(item => {
          let total = lastfillblanksList.filter(list => list.articleName == item).reduce((prev, cur) => {
            return prev + cur.questionList.length;
          }, 0) //注意这里设置了初始值
          this.topics.push({ title: item, topic: lastfillblanksList.filter(list => list.articleName == item), total: total })
        })
      }

      if (radioList.length > 0) {
        this.topics.push({ title: '单选题', topic: radioList, total: radioList.length })
      }


      //作文题的图片的地址要换
      if (composition.length > 0) {
        //需要替换的字符
        const parameter1 = 'video-api';
        //需要替换成的字符
        const parameter2 = 'video-dev';
        //将作文题的图片更换路径
        let lastcomposition = composition.map(item => {
          return { ...item, articleCon: item.articleCon ? item.articleCon.replace(parameter1, parameter2) : '' }
        })
        let compositionType = []
        lastcomposition.forEach(item => {
          if (!compositionType.includes(item.articleName)) {
            compositionType.push(item.articleName)
          }
        });

        compositionType.forEach(item => {
          this.topics.push({ title: item, topic: lastcomposition.filter(list => list.articleName == item), total: lastcomposition.filter(list => list.articleName == item).length })
        })
      }

      console.log(this.topics, 'topice');
      this.alltotal = this.topics.reduce((prev, cur) => {
        return prev + cur.total;
      }, 0) //注意这里设置了初始值
    },
    renderTitle() {
      let start = this.topics.reduce((prev, cur, index) => {
        if (index >= this.topicIndex) {
          return prev
        } else {
          return prev + cur.total;
        }
      }, 0) //注意这里设置了初始值

      let end = this.topics[this.topicIndex].total + start;

      console.log(start, end, 123456);
      if (start + 1 === end) {
        return `第${end}题`
      } else if (start !== 0) {
        return `第${start + 1}-${end}题`

      } else {
        return `第${start + 1}-${end}题`
      }
    },

    toChineseNumber(n) {
      if (!Number.isInteger(n) && n < 0) {
        throw Error('请输入自然数');
      }
      const digits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const positions = ['', '十', '百', '千', '万', '十万', '百万', '千万', '亿', '十亿', '百亿', '千亿'];
      const charArray = String(n).split('');
      let result = '';
      let prevIsZero = false;
      //处理0  deal zero
      for (let i = 0; i < charArray.length; i++) {
        const ch = charArray[i];
        if (ch !== '0' && !prevIsZero) {
          result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
        } else if (ch === '0') {
          prevIsZero = true;
        } else if (ch !== '0' && prevIsZero) {
          result += '零' + digits[parseInt(ch)] + positions[charArray.length - i - 1];
        }
      }
      //处理十 deal ten
      if (n < 100) {
        result = result.replace('一十', '十');
      }
      return result;
    },
    // 下一题
    goNext() {
      if (this.topicIndex < this.topics.length - 1) {
        this.topicIndex = this.topicIndex + 1;
      }
    },
    goUp() {
      if (this.topicIndex > 0) {
        this.topicIndex = this.topicIndex - 1;
      }
    },
    //点击答题卡
    gotopic(key, index) {
      this.topicIndex = key;
      setTimeout(function () {
        document.getElementById('step' + index).scrollIntoView();
      }, 100)
    },
  },
  watch: {
    topicIndex(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.renderTitle()
    },

    topicInfo: {
      handler: function (newVal, oldVal) {
        this.changeInfo()
        console.log(newVal, oldVal);
      },
      deep: true
    }

  }
}
</script>
<style lang="less" scoped>
.md-topic-detail-pages-component {
  background-color: #f2f2f2;
}

.title {
  height: 64px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  box-sizing: border-box;
}

.content {
  display: flex;
  width: 80%;
  // align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.content-left {
  flex: 1;
  background: #fff;
  // height: calc(100vh - 90px);

  .el-divider--horizontal {
    margin: 0 !important;
  }
}

.content-right {
  margin-left: 20px;
  background-color: #fff;
  position: sticky;
  top: 0;
  overflow-y: auto;
  width: 270px;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100vh - 90px);
  border-radius: 5;

  .el-button {
    border-color: #fe6b03;
    color: #fe6b03;

    :hover {}
  }

}

.content-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  align-items: flex-end;
  padding: 0 20px 0 20px;
  z-index: 100;

  >:nth-child(1) {
    flex: 1;
    padding-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    display: flex;

    >:nth-child(1) {
      width: 45%;
    }

    >:nth-child(2) {
      flex-shrink: 0;
    }

    >:nth-child(3) {
      flex-shrink: 0;
      width: 40%;
      display: flex;
      justify-content: right;
    }
  }

}

.font-assistant {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 0px 10px;
}

.submit-btn {
  width: 100%;
}

.margin-driver {
  margin: 24px 0 !important;
}

.font-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0px 5px;
}

.font-regular {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
  }
}

.no-answer-tip {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #E73A39;
  line-height: 20px;
  margin-bottom: 6px;
  padding: 0px 20px;
}


.question-tag {
  cursor: pointer;
  border-radius: 5px;
  width: 45px;
  height: 30px;
  text-align: center;
  border: 1px solid #f2f2f2;
  font-size: 12px;
  font-weight: 400;
  line-height: 29px;
  padding-bottom: 1px;
  margin-top: 10px;
  margin-left: 5px;
}

.el-divider--horizontal {
  margin: 8px 0;
  background: 0 0;
  border-top: 1px dashed #e8eaec;
}

.green {
  cursor: pointer;
  border-radius: 5px;
  width: 45px;
  height: 30px;
  text-align: center;
  border: 1px solid #f2f2f2;
  font-size: 12px;
  font-weight: 400;
  line-height: 29px;
  padding-bottom: 1px;
  margin-top: 10px;
  color: #fff;
  background-color: #27c770;
  margin-left: 5px;
}
</style>
    